import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import page1 from './page1';
import mypage from './mypage';
import propertyList from './propertyList';
import propertyDetails from './propertyDetails';
import askForm from './askForm';
import askFormThanks from './askFormThanks';

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={page1} />
        <Route exact path="/mypage" component={mypage} />
        <Route exact path="/property_list" component={propertyList} />
        <Route exact path="/property_details" component={propertyDetails} />
        <Route exact path="/ask_form" component={askForm} />
        <Route exact path="/ask_form_thanks" component={askFormThanks} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
