import React from 'react'; //Reactを読み込んでいる
import { Link } from 'react-router-dom';
import Header from './components/Header';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import PhoneForwardedIcon from '@material-ui/icons/PhoneForwarded';
import Button from '@material-ui/core/Button';
import EmailIcon from '@material-ui/icons/Email';
import GraphImage from './images/graph.png';

function createData(name, text) {
  return { name, text };
}

const rows = [
  createData('販売価格','0.315億'),
  createData('住所(住居表示)','東京都練馬区北町５丁目8-19'),
  createData('住所(地番)','東京都練馬区'),
  createData('最寄り駅','東武練馬駅 徒歩10分'),
  createData('土地面積（坪）','39.89坪'),
  createData('検査済証',''),
  createData('売却理由',''),
  createData('種類','土地'),
  createData('地目','宅地'),
  createData('用途地域','第一種中高層住居専用地域'),
  createData('防火地域',''),
  createData('角地ですか？','いいえ'),
  createData('建ぺい率','60％'),
  createData('容積率','200％'),
  createData('一種単価','0万円'),
  createData('前面道路の幅員','4.98m'),
  createData('間口','0m'),
  createData('高度地区',''),
  createData('高さ制限','0m'),
];

class propertyList extends React.Component { //page1クラスにReact.Componentを継承する

  render() { //画面表示の為のrenderメソッドを定義する
    return ( 
      <div>
        <Header />
        <main className="main_wrap">
          <Link to="/property_list">＜一覧に戻る</Link>

          <h1>北町５丁目土地</h1>

          <TableContainer component={Paper} className="card_wrap">
            <Table>
              <TableBody>
                  {rows.map((row) => (
                    <TableRow key={row.name}>
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell> 
                      <TableCell>{row.text}</TableCell>     
                    </TableRow>              
                  ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Card className="card_wrap">
            <CardContent>
              <h3>地図</h3>
              <iframe src="https://maps.google.co.jp/maps?&amp;q=東京都練馬区北町５丁目8-19&amp;output=embed" title="map"></iframe>
            </CardContent>
          </Card>

          <Card className="card_wrap">
            <CardContent>
              <h3>AIスコア</h3>
              <div class="ai_score">58</div>
              <p>※AIスコアは100を満点とした、当サービス独自のアルゴリズムによって算出された物件の評価指標です</p>
            </CardContent>
          </Card>

          <Card className="card_wrap">
            <CardContent>
              <h3>収益予想（楽待のCFシミュレーション貼り付けただけ。イメージ。）</h3>
              <div><img src={GraphImage} alt="グラフ" /></div>
            </CardContent>
          </Card>

          <Card className="card_wrap">
            <CardContent>
              <h3>取り扱い業者</h3>
              <p>ひかり商事<br />
              担当：岡崎</p>
              <p><a href="tel:03-XXXX-XXXX" class="app_link"><Button
                variant="contained"
                color="primary"
                startIcon={<PhoneForwardedIcon />}
                fullWidth
              >
                電話で連絡する
              </Button></a></p>
              <p><Link to="/ask_form" className="app_link"><Button
                variant="contained"
                color="secondary"
                startIcon={<EmailIcon />}
                fullWidth
              >
                メールで連絡する
              </Button></Link></p>
            </CardContent>
          </Card>
        </main>
      </div>
    );
  }

}

export default propertyList;