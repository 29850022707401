import React from 'react';              //Reactを読み込んでいる
import { Link } from 'react-router-dom';
import Header from './components/Header';
import './App.css';
import MainImage from './images/main.jpg';
import Button from '@material-ui/core/Button';

class page1 extends React.Component {   //page1クラスにReact.Componentを継承する
  render() {                          //画面表示の為のrenderメソッドを定義する
    return (
      <div>
        <Header />
        <main className="main_wrap">
          <p><img src={MainImage} alt="logo" /></p>
          <h3>契約につながるデータしかありません</h3>
          <p>「サブスク統合版」は、日本全国のあらゆる不動産業者が持つ即契約に結びつくデータだけを取り揃えたサービスです。</p>
          <p><Link to="/property_list" className="app_link"><Button variant="contained" color="primary">
            早速使ってみる
          </Button></Link></p>


        </main>
      </div>
    );
  }
}
 
export default page1;                   //page1を出力する為に必要