import React from 'react'; //Reactを読み込んでいる
import { Link } from 'react-router-dom';
import Header from './components/Header';
import { FormControl } from '@material-ui/core';
import { TextField } from '@material-ui/core';
import { Button } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';

class askForm extends React.Component { 

  render() { //画面表示の為のrenderメソッドを定義する
    return ( 
      <div>
        <Header />
        <main className="main_wrap">
          <h2>問い合わせフォーム</h2>
          <FormControl fullWidth>
            <p><TextField id="standard-basic" defaultValue="北町５丁目土地 " label="物件名" variant="outlined" fullWidth /></p>
            <p><TextField
              id="outlined-multiline-static"
              label="お問い合わせ内容"
              multiline
              rows={10}
              variant="outlined"
              fullWidth
            /></p>
            <Link to="/ask_form_thanks" className="app_link"><Button
              variant="contained"
              color="primary"
              startIcon={<SendIcon />}
              fullWidth
            >
              問い合わせる
            </Button></Link>
          </FormControl>
        </main>
      </div>
    );
  }

}

export default askForm;