import React from 'react'; //Reactを読み込んでいる
import { Link } from 'react-router-dom';
import Header from './components/Header';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

function createData(name, price) {
  return { name, price };
}

function createOurData(name, pv, contact) {
  return { name, pv, contact};
}

const rows = [
  createData('北町５丁目土地','0.315億'),
  createData('北町５丁目土地','0.315億'),
  createData('北町５丁目土地','0.315億'),
  createData('北町５丁目土地','0.315億'),
  createData('北町５丁目土地','0.315億'),
];

const books = [
  createOurData('Aマンション','100','8'),
  createOurData('Bマンション','58','10'),
  createOurData('C一軒家','33','6'),
  createOurData('D土地','55','9'),
  createOurData('北町５丁目土地','72','11'),
];


class propertyList extends React.Component { //page1クラスにReact.Componentを継承する

  render() { //画面表示の為のrenderメソッドを定義する
    return ( 
      <div>
        <Header />
        <main className="main_wrap">
          <h2>マイページ</h2>
          <h3>自社登録物件の反響</h3>
          <TableContainer component={Paper} className="select_wrap">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>物件名</TableCell>
                  <TableCell>閲覧数</TableCell>
                  <TableCell>反響数</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {books.map((book) => (
                  <TableRow key={book.name}>
                    <TableCell component="th" scope="row">
                      <Link to ="/property_details">{book.name}</Link>
                    </TableCell> 
                    <TableCell>{book.pv}</TableCell>
                    <TableCell>{book.contact}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <h3>お気に入り物件</h3>
          <TableContainer component={Paper} className="card_wrap">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>物件名</TableCell>
                  <TableCell>販売価格</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.name}>
                    <TableCell component="th" scope="row">
                      <Link to ="/property_details">{row.name}</Link>
                    </TableCell> 
                    <TableCell>{row.price}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </main>
      </div>
    );
  }

}

export default propertyList;