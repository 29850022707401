import React from 'react'; //Reactを読み込んでいる
import { Link } from 'react-router-dom';
import Header from './components/Header';

class askForm extends React.Component { 

  render() { //画面表示の為のrenderメソッドを定義する
    return ( 
      <div>
        <Header />
        <main className="main_wrap">
          <h2>問い合わせフォーム</h2>
          <p>お問い合わせを受け付けました。</p>
          <p><Link to="/property_list">物件一覧に戻る</Link></p>
        </main>
      </div>
    );
  }

}

export default askForm;