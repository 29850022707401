import React from 'react'; //Reactを読み込んでいる
import { Link } from 'react-router-dom';
import Header from './components/Header';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

class propertyList extends React.Component { //page1クラスにReact.Componentを継承する

  render() { //画面表示の為のrenderメソッドを定義する
    return ( 
      <div>
        <Header />
        <main className="main_wrap">
          <h2>登録物件一覧</h2>
          <div className="card_wrap">
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="demo-simple-select-label">並べ替え</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value=''
              fullWidth
            >
              <MenuItem value={10}>価格が高い順</MenuItem>
              <MenuItem value={20}>価格が安い順</MenuItem>
              <MenuItem value={30}>面積が大きい順</MenuItem>
              <MenuItem value={30}>面積が小さい順</MenuItem>
              <MenuItem value={30}>更新日が新しい順</MenuItem>
              <MenuItem value={30}>更新日が古い順</MenuItem>
            </Select>
          </FormControl>
          </div>

          <Link to="/property_details" className="app_link">
            <Card className="card_wrap">
              <CardActionArea>
                <CardContent>
                  <h3>北町５丁目土地</h3>
                  <Typography variant="h5" className="price" gutterBottom>0.315億</Typography>
                  <p>東京都練馬区北町５丁目8-19<br />
                  東武練馬駅 徒歩10分<br />
                  （第一種中高層住居専用地域）</p>
                  <p>土地面積：39.89坪　建物面積：0坪<br />
                  建ぺい率：60%　容積率：200%<br />
                  前面道路幅員：4.98m<br />
                  </p>
                </CardContent>
              </CardActionArea>
            </Card>
          </Link>

          <Link to="/property_details" className="app_link">
            <Card className="card_wrap">
              <CardActionArea>
                <CardContent>
                  <h3>北町５丁目土地</h3>
                  <Typography variant="h5" className="price" gutterBottom>0.315億</Typography>
                  <p>東京都練馬区北町５丁目8-19<br />
                  東武練馬駅 徒歩10分<br />
                  （第一種中高層住居専用地域）</p>
                  <p>土地面積：39.89坪　建物面積：0坪<br />
                  建ぺい率：60%　容積率：200%<br />
                  前面道路幅員：4.98m<br />
                  </p>
                </CardContent>
              </CardActionArea>
            </Card>
          </Link>

          <Link to="/property_details" className="app_link">
            <Card className="card_wrap">
              <CardActionArea>
                <CardContent>
                  <h3>北町５丁目土地</h3>
                  <Typography variant="h5" className="price" gutterBottom>0.315億</Typography>
                  <p>東京都練馬区北町５丁目8-19<br />
                  東武練馬駅 徒歩10分<br />
                  （第一種中高層住居専用地域）</p>
                  <p>土地面積：39.89坪　建物面積：0坪<br />
                  建ぺい率：60%　容積率：200%<br />
                  前面道路幅員：4.98m<br />
                  </p>
                </CardContent>
              </CardActionArea>
            </Card>
          </Link>

          <Link to="/property_details" className="app_link">
            <Card className="card_wrap">
              <CardActionArea>
                <CardContent>
                  <h3>北町５丁目土地</h3>
                  <Typography variant="h5" className="price" gutterBottom>0.315億</Typography>
                  <p>東京都練馬区北町５丁目8-19<br />
                  東武練馬駅 徒歩10分<br />
                  （第一種中高層住居専用地域）</p>
                  <p>土地面積：39.89坪　建物面積：0坪<br />
                  建ぺい率：60%　容積率：200%<br />
                  前面道路幅員：4.98m<br />
                  </p>
                </CardContent>
              </CardActionArea>
            </Card>
          </Link>

          <Link to="/property_details" className="app_link">
            <Card className="card_wrap">
              <CardActionArea>
                <CardContent>
                  <h3>北町５丁目土地</h3>
                  <Typography variant="h5" className="price" gutterBottom>0.315億</Typography>
                  <p>東京都練馬区北町５丁目8-19<br />
                  東武練馬駅 徒歩10分<br />
                  （第一種中高層住居専用地域）</p>
                  <p>土地面積：39.89坪　建物面積：0坪<br />
                  建ぺい率：60%　容積率：200%<br />
                  前面道路幅員：4.98m<br />
                  </p>
                </CardContent>
              </CardActionArea>
            </Card>
          </Link>

          <Link to="/property_details" className="app_link">
            <Card className="card_wrap">
              <CardActionArea>
                <CardContent>
                  <h3>北町５丁目土地</h3>
                  <Typography variant="h5" className="price" gutterBottom>0.315億</Typography>
                  <p>東京都練馬区北町５丁目8-19<br />
                  東武練馬駅 徒歩10分<br />
                  （第一種中高層住居専用地域）</p>
                  <p>土地面積：39.89坪　建物面積：0坪<br />
                  建ぺい率：60%　容積率：200%<br />
                  前面道路幅員：4.98m<br />
                  </p>
                </CardContent>
              </CardActionArea>
            </Card>
          </Link>

          <Link to="/property_details" className="app_link">
            <Card className="card_wrap">
              <CardActionArea>
                <CardContent>
                  <h3>北町５丁目土地</h3>
                  <Typography variant="h5" className="price" gutterBottom>0.315億</Typography>
                  <p>東京都練馬区北町５丁目8-19<br />
                  東武練馬駅 徒歩10分<br />
                  （第一種中高層住居専用地域）</p>
                  <p>土地面積：39.89坪　建物面積：0坪<br />
                  建ぺい率：60%　容積率：200%<br />
                  前面道路幅員：4.98m<br />
                  </p>
                </CardContent>
              </CardActionArea>
            </Card>
          </Link>

          <Link to="/property_details" className="app_link">
            <Card className="card_wrap">
              <CardActionArea>
                <CardContent>
                  <h3>北町５丁目土地</h3>
                  <Typography variant="h5" className="price" gutterBottom>0.315億</Typography>
                  <p>東京都練馬区北町５丁目8-19<br />
                  東武練馬駅 徒歩10分<br />
                  （第一種中高層住居専用地域）</p>
                  <p>土地面積：39.89坪　建物面積：0坪<br />
                  建ぺい率：60%　容積率：200%<br />
                  前面道路幅員：4.98m<br />
                  </p>
                </CardContent>
              </CardActionArea>
            </Card>
          </Link>

          <Link to="/property_details" className="app_link">
            <Card className="card_wrap">
              <CardActionArea>
                <CardContent>
                  <h3>北町５丁目土地</h3>
                  <Typography variant="h5" className="price" gutterBottom>0.315億</Typography>
                  <p>東京都練馬区北町５丁目8-19<br />
                  東武練馬駅 徒歩10分<br />
                  （第一種中高層住居専用地域）</p>
                  <p>土地面積：39.89坪　建物面積：0坪<br />
                  建ぺい率：60%　容積率：200%<br />
                  前面道路幅員：4.98m<br />
                  </p>
                </CardContent>
              </CardActionArea>
            </Card>
          </Link>

          <Link to="/property_details" className="app_link">
            <Card className="card_wrap">
              <CardActionArea>
                <CardContent>
                  <h3>北町５丁目土地</h3>
                  <Typography variant="h5" className="price" gutterBottom>0.315億</Typography>
                  <p>東京都練馬区北町５丁目8-19<br />
                  東武練馬駅 徒歩10分<br />
                  （第一種中高層住居専用地域）</p>
                  <p>土地面積：39.89坪　建物面積：0坪<br />
                  建ぺい率：60%　容積率：200%<br />
                  前面道路幅員：4.98m<br />
                  </p>
                </CardContent>
              </CardActionArea>
            </Card>
          </Link>


        </main>
      </div>
    );
  }

}

export default propertyList;